import React from 'react';

function Intro() {
  return (
    <section className="o-section t-section ">
      <div className="o-section__header-bg t-section__header" />
      <div className="o-section__content-bg t-section__content" />
      <div className="o-container">
        <div className="o-section__container">
          <header className="o-section__header t-section__header">
            <div className="o-content">
              <h2 className="o-section__heading">Intro</h2>
              <div className="o-content__body o-section__description">
                <div className="u-left-description">What I am all about.</div>
              </div>
            </div>
          </header>
          <div className="o-section__content t-section__content">
            <div className="o-content">
              <div className="c-intro">
                <div className="o-content__body">
                  <p>
                    <strong>
                      Dynamic, passionate, and highly motivated IT professional
                    </strong>{' '}
                    with over <strong>15 years of experience</strong> and a
                    proven track record in software development, including
                    significant work in Web3 technologies. Currently, I am
                    leveraging my expertise as part of <strong>Lympid</strong>,
                    a fully regulatory-compliant platform that issues and trades
                    premium Real World Assets (RWAs) such as horses, art, luxury
                    watches, US treasuries, and real estate. At{' '}
                    <strong>Lympid</strong>, I contribute to creating a
                    straightforward, transparent path to alternative investment
                    opportunities, making premium assets accessible to everyone.
                  </p>
                  <p>
                    My background encompasses pioneering decentralized
                    applications for platforms such as{' '}
                    <strong>Polkastarter</strong> and <strong>Filtered</strong>,
                    where I have leveraged blockchain to enhance functionality.
                    An excellent team player, I thrive on collaborating with
                    other IT professionals to create{' '}
                    <strong>high-standard software solutions</strong> that stay
                    at the forefront of technology trends, particularly in
                    blockchain practices. My approach is{' '}
                    <strong>analytical and creative</strong>, ensuring that all
                    projects not only meet initial requirements but also
                    incorporate robust, scalable technologies.
                  </p>
                  <p>
                    A proficient problem-solver, I am capable of{' '}
                    <strong>
                      managing projects from inception to completion
                    </strong>
                    , maintaining them within budget and on schedule.
                    Hard-working and eager to embrace new challenges, I am
                    dedicated to advancing my expertise in <strong>Web3</strong>{' '}
                    while continuing to deliver exceptional results in all
                    aspects of software development. My{' '}
                    <strong>
                      strong sense of responsibility in maintaining client
                      relations and well-honed communication skills
                    </strong>{' '}
                    make me a reliable partner in any innovative tech venture.
                  </p>

                  <h3 className="u-mt-25">Interests</h3>
                  <p>
                    I enjoy traveling to explore different cultures, staying
                    active with sports, and relaxing through cinema and music.
                    My leisure reading spans from fiction to philosophy, and I
                    have a keen interest in the transformative potential of
                    technology in everyday life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Intro;

import React from 'react';

import LanguageItem from './LanguageItem';

function Languages() {
  return (
    <section className="o-section t-section ">
      <div className="o-section__header-bg t-section__header" />
      <div className="o-section__content-bg t-section__content" />
      <div className="o-container">
        <div className="o-section__container">
          <header className="o-section__header t-section__header">
            <div className="o-content">
              <h2 className="o-section__heading">Languages</h2>
              <div className="o-content__body o-section__description">
                <div className="u-left-description">
                  Mourinho would be jealous.
                </div>
              </div>
            </div>
          </header>
          <div className="o-section__content t-section__content">
            <div className="o-grid">
              <LanguageItem greeting="Olá!" name="Portuguese" grade="Native" />
              <LanguageItem
                greeting="Hello!"
                name="English"
                grade="Professional"
              />
              <LanguageItem
                greeting="Hola!"
                name="Spanish"
                grade="Elementary"
              />
              <LanguageItem
                greeting="Привіт!"
                name="Ukrainian"
                grade="Elementary"
              />
              <LanguageItem
                greeting="Cześć!"
                name="Polish"
                grade="Elementary"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Languages;

import React from 'react';
import Fade from 'react-reveal/Fade';

import ThemeSwitch from './ThemeSwitch';
import Header from './Header';
import Intro from './Intro';
import Skills from './Skills';
import Experience from './Experience';
import AdditionalExperience from './AdditionalExperience';
import Education from './Education';
import Languages from './Languages';
import Contact from './Contact';

function App() {
  return (
    <div className="c-main-container">
      <ThemeSwitch />
      <Header />
      <Fade>
        <Intro />
      </Fade>
      <Fade>
        <Skills />
      </Fade>
      <Fade>
        <Experience />
      </Fade>
      <Fade>
        <AdditionalExperience />
      </Fade>
      <Fade>
        <Education />
      </Fade>
      <Fade>
        <Languages />
      </Fade>
      <Fade>
        <Contact />
      </Fade>
    </div>
  );
}

export default App;

import React from 'react';

import EducationItem from './EducationItem';

function Education() {
  return (
    <section className="o-section t-section">
      <div className="o-section__header-bg t-section__header" />
      <div className="o-section__content-bg t-section__content" />
      <div className="o-container">
        <div className="o-section__container">
          <header className="o-section__header t-section__header">
            <div className="o-content">
              <h2 className="o-section__heading">
                Education
              </h2>
              <div className="o-content__body o-section__description">
                <div className="u-left-description">
                  Learn as if you were to live forever.
                </div>
              </div>
            </div>
          </header>
          <div className="o-section__content t-section__content">
            <div className="o-grid">
              <EducationItem graduation="Certificate of Pedagogical Aptitude Trainer" school="Rumos" year="2012" />
              <EducationItem graduation="Microsoft Certified Professional Developer" school="Rumos (Microsoft)" year="2010" />
              <EducationItem graduation="Microsoft Certified Technology Specialist" school="Rumos (Microsoft)" year="2010" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Education;

import React, { useEffect, useState } from 'react';

function ThemeSwitch() {
  const [theme, setTheme] = useState(() => {
    const persistedValue = getStorageValue();
    let defaultValue = 'theme-light';
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      defaultValue = 'theme-dark';
    }
    return getStorageValue() !== null ? persistedValue : defaultValue;
  });

  useEffect(() => {
    document.body.className = theme;
  });

  function getStorageValue() {
    return localStorage.getItem('theme');
  }

  function saveStorageValue(themeValue) {
    localStorage.setItem('theme', themeValue);
  }

  function toggleDarkLight() {
    let currentTheme = document.body.className === 'theme-light' ? 'theme-dark' : 'theme-light';

    setTheme(currentTheme);
    saveStorageValue(currentTheme);
  }

  return (
    <div className="theme-switch">
      <label id="switch" className="switch">
        <input type="checkbox" onChange={() => toggleDarkLight()} id="slider" defaultChecked={theme === 'theme-dark'} />
        <span className="slider round" />
      </label>
    </div>
  );
}

export default ThemeSwitch;

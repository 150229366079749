import React from 'react';

function SkillItem(props) {
  return (
    <div className="o-grid__col-sm-6">
      <div className="o-content">
        <div className="o-media o-media--block">
          <div className="o-media__figure">
            <div className="c-number t-primary-color">
              {props.percentage}<small>%</small>
            </div>
          </div>
          <div className="o-media__body">
            <h3>{props.skill}</h3>
          </div>
        </div>
        <div className="c-progress-bar o-content__body t-border-color-bg u-mt-text">
          <div className="a-progress-bar c-progress-bar__filler t-primary-bg" data-percent={props.percentage} />
        </div>
      </div>
    </div>
  );
}

export default SkillItem;

import React from 'react';
import Fade from 'react-reveal/Fade';

import ExperienceItem from './ExperienceItem';

function Experience() {
  return (
    <section className="o-section t-section">
      <div className="o-section__header-bg t-section__header" />
      <div className="o-section__content-bg t-section__content" />
      <div className="o-container">
        <div className="o-section__container">
          <header className="o-section__header t-section__header">
            <div className="o-content">
              <h2 className="o-section__heading">Experience</h2>
              <div className="o-content__body o-section__description">
                <div className="u-left-description">Yes. I've been around.</div>
              </div>
            </div>
          </header>
          <div className="o-section__content t-section__content u-pb-0">
            <div className="a-experience-timeline c-timeline t-border-color">
              <Fade delay={50}>
                <ExperienceItem
                  startYear="2024"
                  endYear="Present"
                  companyName="Lympid"
                  companyURL="https://lympid.io"
                  aboutCompany="Lympid is a fully regulatory-compliant platform to issue and trade premium RWAs. Horses, Art, Luxury Watches, US Treasuries, Real Estate, and much more."
                  positionTitle="CTO & Full Stack Developer"
                  workLocation="Lisbon, Portugal"
                  responsabilityPills={[
                    'Node.js',
                    'React',
                    'Bash',
                    'Python',
                    'Javascript',
                    'HTML/SASS',
                    'Solidity',
                    'Git',
                    'Linux',
                  ]}
                />
              </Fade>
              <Fade delay={100}>
                <ExperienceItem
                  startYear="2021"
                  endYear="2024"
                  companyName="Polkastarter"
                  companyURL="https://polkastarter.com"
                  aboutCompany="Polkastarter is a premier Web3 fundraising platform that helps Web3 projects launch decentralized and fixed swap token pools. It enables backers to invest early in IDOs, NFTs, and gaming projects securely and compliantly."
                  positionTitle="Analytics Lead Developer"
                  workLocation="Lisbon, Portugal"
                  responsabilityBullets={[
                    "<strong>Developed a comprehensive infrastructure</strong> from scratch to analyze the utility and transaction activity of Polkastarter's token across multiple blockchains.",
                    '<strong>Led data-driven strategies</strong> to enhance understanding of IDO performances, using blockchain data to inform growth initiatives and improve user engagement.',
                  ]}
                  responsabilityPills={[
                    'PHP',
                    'React',
                    'Python',
                    'Javascript',
                    'HTML/SASS',
                    'Solidity',
                    'Git',
                    'Linux',
                    'Bash',
                  ]}
                />
              </Fade>
              <Fade delay={150}>
                <ExperienceItem
                  startYear="2017"
                  endYear="2024"
                  companyName="Chipp'd"
                  companyURL="https://chippd.com"
                  brands={[
                    {
                      name: 'Talsam',
                      url: 'https://web.archive.org/web/20210301063035/https://talsam.com/web/20210301063035/https://talsam.com/',
                    },
                    {
                      name: 'Filtered',
                      url: 'https://filtered.art',
                    },
                  ]}
                  aboutCompany="Chipp’d merges physical products with digital content using IoT technologies such as QR codes, Bluetooth, and WiFi. Their offerings range from interactive greeting cards to buttons that unlock exclusive content. Chipp’d also operates Filtered, an NFT art curation platform"
                  positionTitle="CTO & Full Stack Developer"
                  workLocation="Lisbon, Portugal"
                  responsabilityBullets={[
                    "<strong>Integrated Web3 technologies</strong> in Chipp’d's digital offerings, including the NFT art curation platform, Filtered, focusing on the scalability and security of digital assets.",
                    '<strong>Directed a remote team</strong> in developing IoT solutions and enhancing platform functionalities, fostering innovation and improving user interaction with digital content.',
                  ]}
                  responsabilityPills={[
                    'PHP',
                    'Node.js',
                    'Angular',
                    'Express',
                    'Ruby',
                    'MySQL',
                    'HTML/SASS',
                    'Redis',
                    'Git',
                    'AWS',
                    'Apache',
                    'Nginx',
                    'Bash',
                    'Linux',
                    'Solidity',
                    'MongoDB',
                  ]}
                />
              </Fade>
              <Fade delay={200}>
                <ExperienceItem
                  startYear="2015"
                  endYear="2017"
                  companyName="Tradiio"
                  companyURL="https://tradiio.com"
                  aboutCompany="Tradiio was a music app that functioned as an artist accelerator, enabling users to discover and support new artists in exchange for exclusive rewards. The platform facilitated connections between artists and industry professionals, providing tools to help artists gain visibility and succeed."
                  positionTitle="Full Stack Developer & Product Growth"
                  workLocation="Lisbon, Portugal"
                  responsabilityBullets={[
                    '<strong>Demonstrated proficiency</strong> by creating and implementing processes for platform growth.',
                    '<strong>Bridged</strong> the product team with marketing and development, focusing on key performance indicators (KPI).',
                    '<strong>Enhanced</strong> the codebase and stayed updated with new technology stacks.',
                  ]}
                  responsabilityPills={[
                    'Laravel (PHP)',
                    'Node.js',
                    'Angular',
                    'Express',
                    'MySQL',
                    'HTML/SASS',
                    'Redis',
                    'Git',
                    'AWS',
                    'Azure',
                    'Apache',
                    'Nginx',
                    'Bash',
                    'Linux',
                  ]}
                />
              </Fade>
              <Fade delay={250}>
                <ExperienceItem
                  startYear="2013"
                  endYear="2015"
                  companyName="WayNext"
                  companyURL="https://waynext.pt"
                  aboutCompany="WayNext is a leading digital agency established in 2002 in Lisbon. The agency focuses on various aspects of digital marketing including strategy, creativity, technology, content, social media, and promotional activities for web and mobile platforms."
                  positionTitle="Full Stack Web Developer"
                  workLocation="Lisbon, Portugal"
                  responsabilityBullets={[
                    '<strong>Developed web solutions</strong> for major players in the B2C and B2B markets, including banking, telecom, consumer products, and brands.',
                    '<strong>Implemented innovative designs</strong> into HTML and CSS, corrected bugs in existing applications, and maintained client websites.',
                    '<strong>Managed marketing campaigns</strong>, aligning with brand strategy and revenue growth.',
                  ]}
                  responsabilityPills={[
                    'PHP',
                    'Python',
                    'Javascript',
                    'MySQL',
                    'HTML/SASS',
                    'Git',
                    'Apache',
                    'Varnish',
                    'Memcache',
                    'Lucene SOLR',
                    'Bash',
                    'Linux',
                  ]}
                />
              </Fade>
              <Fade delay={300}>
                <ExperienceItem
                  startYear="2012"
                  endYear="2014"
                  companyName="FullFlex"
                  positionTitle="Founder & Full Stack Developer"
                  aboutCompany="FullFlex was a digital agency that specialized in a range of digital marketing services, including strategy, creativity, technology, content creation, social media management, and promotions for web and mobile platforms."
                  workLocation="Lisbon, Portugal"
                  responsabilityBullets={[
                    '<strong>Managed accounts</strong>, created web applications for digital marketing campaigns, and updated procedures based on requirements, user stories, and client discussions.',
                    '<strong>Generated sales</strong> and maintained client relationships.',
                  ]}
                  responsabilityPills={[
                    'Wordpress (PHP)',
                    'Javascript',
                    'HTML/CSS',
                    'MySQL',
                    'Git',
                    'Linux',
                  ]}
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience;

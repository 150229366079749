import React from 'react';

function ExperienceItem(props) {
  function returnHeading() {
    if (props.brands) {
      return (
        <h3 className="c-work__heading">
          {props.companyName}{' '}
          <small>
            (
            {props.brands.map((item, index) => {
              if (item.url) {
                if (index + 1 != props.brands.length) {
                  return (
                    <>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.name}
                      </a>
                      {', '}
                    </>
                  );
                } else {
                  return (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  );
                }
              } else {
                if (index + 1 != props.brands.length) {
                  return item.name + ', ';
                } else {
                  return item.name;
                }
              }
            })}
            )
          </small>
        </h3>
      );
    } else {
      if (props.companyURL) {
        return (
          <h3 className="c-work__heading">
            <a
              href={props.companyURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.companyName}
            </a>
          </h3>
        );
      } else {
        return <h3 className="c-work__heading">{props.companyName}</h3>;
      }
    }
  }

  function returnResponsabilityBullets() {
    if (props.responsabilityBullets) {
      return (
        <>
          <p className="responsibilities">Responsibilities:</p>
          <ul>
            {props.responsabilityBullets.map((item, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
            ))}
          </ul>
        </>
      );
    }
  }

  function returnTechnologyPills() {
    if (props.responsabilityPills) {
      return (
        <ul className="c-work__technology-pills">
          {props.responsabilityPills.map((e, i) => (
            <li className="c-work__technology-pill" key={i}>
              {e}
            </li>
          ))}
        </ul>
      );
    }
  }

  return (
    <div className="c-timeline__item">
      <div className="c-timeline__point t-timeline__point t-primary-bg" />
      <div className="o-content">
        <div className="o-grid">
          <div className="o-grid__col-md-4">
            <div className="c-work__timeframe">
              {props.startYear} – {props.endYear}
            </div>
            {returnHeading()}
            <h4 className="c-work__title">{props.positionTitle}</h4>
            <div className="c-work__location">{props.workLocation}</div>
          </div>
          <div className="o-grid__col-md-8">
            <p className="about">{props.aboutCompany}</p>
            {returnResponsabilityBullets()}
          </div>
        </div>
        <div className="o-grid u-mt-055">
          <div className="o-grid__col-md-4">
            <div className="c-work__tech">Technologies used</div>
          </div>
          <div className="o-grid__col-md-8">{returnTechnologyPills()}</div>
        </div>
      </div>
    </div>
  );
}

export default ExperienceItem;

import React, { useEffect } from 'react';

import SkillItem from './SkillItem';

function Skills() {
  let windowHeight = window.innerHeight;
  let elements = null;

  useEffect(() => {
    windowHeight = window.innerHeight;
    elements = document.querySelectorAll('.a-progress-bar');

    window.addEventListener('scroll', checkPosition);
  });

  function checkPosition() {
    if (elements) {
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        var positionFromTop = elements[i].getBoundingClientRect().top;

        if (positionFromTop - windowHeight <= 0) {
          element.style.width = element.getAttribute('data-percent') + '%';
          element.classList.add('in-view');
        }
      }
    }
  }

  return (
    <section className="o-section t-section">
      <div className="o-section__header-bg t-section__header" />
      <div className="o-section__content-bg t-section__content" />
      <div className="o-container">
        <div className="o-section__container">
          <header className="o-section__header t-section__header">
            <div className="o-content">
              <h2 className="o-section__heading">Skills & Tools</h2>
              <div className="o-content__body o-section__description">
                <div className="u-left-description">
                  Smooth seas do not make skillful sailors.
                </div>
              </div>
            </div>
          </header>
          <div className="o-section__content t-section__content ">
            <div className="o-grid">
              <SkillItem skill="PHP, Javascript, Typescript" percentage="95" />
              <SkillItem skill="Node.js, Linux, Bash, DevOps" percentage="90" />
              <SkillItem skill="HTML5, CSS3, Sass, Less" percentage="90" />
              <SkillItem skill="MySQL, Nginx, Redis, Docker" percentage="85" />
              <SkillItem skill="React, React Native, Angular" percentage="75" />
              <SkillItem skill="Ruby, Python, Node, Solidity" percentage="67" />
              <div className="o-grid__col-sm-12">
                <div className="o-content">
                  <h3>Others</h3>
                  <ul className="c-skills-pills">
                    <li className="c-skills-pill">Laravel</li>
                    <li className="c-skills-pill">Express</li>
                    <li className="c-skills-pill">Objection</li>
                    <li className="c-skills-pill">Knex</li>
                    <li className="c-skills-pill">Jest</li>
                    <li className="c-skills-pill">Next.Js</li>
                    <li className="c-skills-pill">Ruby on Rails</li>
                    <li className="c-skills-pill">AWS</li>
                    <li className="c-skills-pill">Azure</li>
                    <li className="c-skills-pill">Truffle</li>
                    <li className="c-skills-pill">Hardhat</li>
                    <li className="c-skills-pill">Postgres</li>
                    <li className="c-skills-pill">MongoDB</li>
                    <li className="c-skills-pill">TensorFlow</li>
                    <li className="c-skills-pill">Pentesting</li>
                    <li className="c-skills-pill">Git</li>
                    <li className="c-skills-pill">BLE</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;

import React from 'react';

function EducationItem(props) {
  return (
    <div className="o-grid__col-md-4 o-grid__col-sm-6">
      <div className="o-content">
        <hr className="c-deco-line t-primary-color-line" />
        <div className="o-content__body">
          <h3 className="u-mh-education">{props.graduation}</h3>
          <p>
            {props.school}<br />{props.year}
          </p>
        </div>
      </div>
    </div>
  );
}

export default EducationItem;

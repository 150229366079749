import React from 'react';

function LanguageItem(props) {

  function returnGreeting() {
    if (props.name === 'Russian') {
      return (
        <div className="c-greeting rus t-primary-color">
          {props.greeting}
        </div>
      );
    } else if (props.name === 'Polish') {
      return (
        <div className="c-greeting pol t-primary-color">
          {props.greeting}
        </div>
      );
    } else {
      return (
        <div className="c-greeting t-primary-color">
          {props.greeting}
        </div>
      );
    }
  }

  return (
    <div className="o-grid__col-sm-4">
      <div className="o-media o-media--block o-content">
        <div className="o-media__figure-language">
          {returnGreeting()}
        </div>
        <div className="o-media__body o-content__body">
          <h3>{props.name}<br /><small>{props.grade}</small></h3>
        </div>
      </div>
    </div>
  );
}

export default LanguageItem;

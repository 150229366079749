import React from 'react';
import Fade from 'react-reveal/Fade';

import ExperienceSmallItem from './ExperienceSmalltem';

function AdditionalExperience() {
  return (
    <section className="o-section t-section ">
      <div className="o-section__header-bg t-section__header" />
      <div className="o-section__content-bg t-section__content" />
      <div className="o-container">
        <div className="o-section__container">
          <header className="o-section__header t-section__header">
            <div className="o-content">
              <h2 className="o-section__heading">
                Additional Experience
              </h2>
              <div className="o-content__body o-section__description">
                <div className="u-left-description">
                  And round.
                </div>
              </div>
            </div>
          </header>
          <div className="o-section__content t-section__content u-pt-0">
            <div className="o-content">
              <div className="a-additional-experience-timeline c-timeline t-border-color o-section__full-top-space in-view">
                <div className="c-timeline__end t-border-color" />
                <Fade delay={50}>
                  <ExperienceSmallItem
                    startYear="2020"
                    endYear="2020"
                    companyName="Wodly"
                    positionTitle="React Native Developer"
                    workLocation="Lisbon, Portugal"
                    responsabilityPills={['React', 'React Native', 'Javascript', 'Swift', 'Git', 'Linux', 'Bash', 'Tensor Flow']} />
                </Fade>
                <Fade delay={100}>
                  <ExperienceSmallItem
                    startYear="2014"
                    endYear="2014"
                    companyName="Naspers"
                    brandName="OLX"
                    brandURL="https://olx.pt"
                    positionTitle="Web Developer"
                    workLocation="Lisbon, Portugal"
                    responsabilityPills={['PHP', 'Javascript', 'MySQL', 'HTML/SASS/LESS', 'Git', 'Apache', 'Linux']} />
                </Fade>
                <Fade delay={150}>
                  <ExperienceSmallItem
                    startYear="2007"
                    endYear="2011"
                    companyName="FAVVUS-ITHR"
                    positionTitle="Software Developer (C# .NET)"
                    workLocation="Lisbon, Portugal"
                    responsabilityPills={['C#', '.NET', 'Microsoft SQL Server']} />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdditionalExperience;

import React from 'react';

function ExperienceSmallItem(props) {

  function returnHeading() {
    if (props.brandName) {
      if (props.brandURL) {
        return (
          <h3 className="c-work__heading">
            {props.companyName} <a href={props.brandURL} target="_blank" rel="noopener noreferrer"><small>({props.brandName})</small></a>
          </h3>
        );
      } else {
        return (
          <h3 className="c-work__heading">
            {props.companyName} <small>({props.brandName})</small>
          </h3>
        );
      }
    } else {
      if (props.companyURL) {
        return (
          <h3 className="c-work__heading">
            <a href={props.companyURL} target="_blank" rel="noopener noreferrer">{props.companyName}</a>
          </h3>
        );
      } else {
        return (
          <h3 className="c-work__heading">
            {props.companyName}
          </h3>
        );
      }
    }
  }

  function returnTechnologyPills() {
    if (props.responsabilityPills) {
      return (
        <ul className="c-work__technology-pills">
          {props.responsabilityPills.map((e, i) => <li className="c-work__technology-pill" key={i}>{e}</li>)}
        </ul>
      );
    }
  }

  return (
    <div className="c-timeline__item">
      <div className="c-timeline__point t-timeline__point t-primary-bg" />
      <div className="o-content">
        <div className="o-grid">
          <div className="o-grid__col-md-4">
            <div className="c-work__timeframe">
              {props.startYear} – {props.endYear}
            </div>
            {returnHeading()}
            <h4 className="c-work__title">
              {props.positionTitle}
            </h4>
            <div className="c-work__location">
              {props.workLocation}
            </div>
          </div>
          <div className="o-grid__col-md-8">
            <div className="c-work__technology-pills-wrapper u-mt-055">
              <div className="c-work__tech">
                Technologies used
              </div>
              {returnTechnologyPills()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExperienceSmallItem;
